import React, { useEffect, useRef, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import Endpoints from "../../config/APIEndpoints.json";
import CartSidebar from "../productDetails/CartSidebar";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import { getDataFromCookies } from "../Cookies/Cookies";
import HomeSkeleton from "./HomeSkeleton";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import NoProductsAvailable from "../../Assets/NoProductsAvailable.webp";

import "./products.css";

import "./filterSidebar.css";
const Card = React.lazy(() => import("../Card/Card"));
const SearchList = () => {
  const contextObj = useContext(ParamsContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = atob(queryParams.get("query"));
  const [showFeatures, setShowFeatures] = useState({
    cartSidebar: true,
  });
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        cartSidebar: featureFlags.buyer_common_addtocartbar,
      }));

      setShowFeatures((prev) => ({
        ...prev,
        flagsUpdated: true,
      }));
    }
  }, [contextObj.params.featureFlags]);
  useEffect(() => {
    if (contextObj.params.showCartSidebar && showFeatures.cartSidebar) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [contextObj.params.showCartSidebar, showFeatures.cartSidebar]);

  const [maintainanceMode, setMaintainanceMode] = useState(false);
  let [productsLoading, setproductsLoading] = useState(false);
  

  const API_HOST = process.env.REACT_APP_API_HOST;
  
  
  let [nextPageUrl, setNextPageUrl] = useState(null);

  const fetchNextProduct = async () => {
    await fetch(`${nextPageUrl}&query=${searchQuery}&per_page=16`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else if (res.status === 200) {
          if (res.data) {
            setProducts((prevProducts) => [
              ...prevProducts,
              ...res.data.products,
            ]);

            setNextPageUrl(res.data.next_page_url);
            if (res.data.current_page < res.data.last_page) {
              setScroll(true);
            } else {
              setScroll(false);
            }
          }
        } else {
          setScroll(false);
          setNextPageUrl(null);
        }
      });
  };

  const [scroll, setScroll] = useState(false);
  const [loading, setLoading] = useState(true);
  const pageTopRef = useRef(null);
  const [fetchDataNow, setFetchDataNow] = useState(false);

  const [products, setProducts] = useState([]);
  const callSearchAPI = async () => {
    setLoading(true);
    if (searchQuery) {
      if (
        searchQuery !== "" &&
        searchQuery !== undefined &&
        searchQuery !== null
      ) {
        let headers;
        if (getDataFromCookies("isLoggedIn")) {
          const authenticationToken = getDataFromCookies("7H3K9R5P2Q") || "";
          headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticationToken}`,
          };
        } else {
          headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
          };
        }
        await fetch(
          `${API_HOST}/${Endpoints.searchbarQuery}${searchQuery}&per_page=16`,
          {
            method: "GET",
            headers: headers,
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            if (
              res.status == 500 ||
              res.status == 501 ||
              res.status == 502 ||
              res.status == 503 ||
              (res.message &&
                res.message.toLowerCase().includes("server error"))
            ) {
              if (res.status == 503) {
                setMaintainanceMode(true);
              } else {
                setMaintainanceMode(false);
              }
              contextObj.setInput("serviceUnavailable", true);
            } else if (res.status === 200) {
              if (res.data) {
                setProducts(res.data.products);
                setNextPageUrl(res.data.next_page_url);
                if (res.data.current_page < res.data.last_page) {
                  setScroll(true);
                } else {
                  setScroll(false);
                }
              }
            }

            if (res.status != 200) {
              setProducts([]);
              setNextPageUrl(null);
              setScroll(false);
            }
          });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    callSearchAPI();
  }, [searchQuery, contextObj.params.isLoggedIn]);

  return (
    <>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv">
          {loading ? (
            <div className="homepageContainer">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <HomeSkeleton />
              </div>
            </div>
          ) : (
            <>
              <div ref={pageTopRef} className="homepageContainer">
                <div
                  className="ProductListContainer"
                  style={{
                    padding: "20px 0px",
                  }}
                >
                  {contextObj.params.showCartSidebar &&
                    showFeatures.cartSidebar && (
                      <div className="WebViewOnly">
                        {" "}
                        <CartSidebar
                          fetchDataNow={fetchDataNow}
                          resetFetchDataNow={setFetchDataNow}
                        />{" "}
                      </div>
                    )}

                  <>
                    <div className="product-container">
                      {products.length === 0 ? (
                        <div className="NoProductsshowMessage">
                          <div className="NoProductsAvailableImgContainer">
                            <img
                              src={NoProductsAvailable}
                              alt="No Products Available"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              onError={(e) => {
                                e.target.src = placeHolderImage;
                              }}
                              loading="lazy"
                            />
                          </div>
                          <li className="listNameNoPro">
                            {" "}
                            No products available{" "}
                          </li>
                        </div>
                      ) : (
                        <InfiniteScroll
                          dataLength={products.length}
                          next={fetchNextProduct}
                          hasMore={scroll}
                          style={{
                            scrollbarWidth: "none",
                          }}
                        >
                          <>
                            {productsLoading ? (
                              <HomeSkeleton />
                            ) : (
                              <>
                                <div
                                  className="product-list"
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  {products.length > 0 &&
                                    products.map((product, index) => (
                                      <Card
                                        key={index}
                                        idParameret={index}
                                        item={product}
                                        fetchCartDetails={setFetchDataNow}
                                      />
                                    ))}
                                </div>
                                {scroll && (
                                  <div className="loading-more">
                                    Hold on, loading products...
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        </InfiniteScroll>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SearchList;
