import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import "./searchbar.css";
import LazyImage from "../LazyImage/LazyImage";
import "./searchbar.css";
import { getDataFromCookies, setDataToCookies } from "../Cookies/Cookies";
const API_HOST = process.env.REACT_APP_API_HOST;

const SearchBar = ({ showSearchBackground, clearSeachBox }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [productList, setProductList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const contextObj = useContext(ParamsContext);
  const [emptyQuery, setEmptyQuery] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmptyList, setShowEmptyList] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
  });
  const [recentSearches, setRecentSearches] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const recentSearchDivRef = useRef(null);
  const [recentSearchShowDropdown, setRecentSearchShowDropdown] =
    useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    function handleKeyDown(event) {
      if (
        event.key === "ArrowDown" ||
        event.key === "ArrowUp" ||
        event.key === "Enter"
      ) {
        if (!dropdownRef.current) return;

        const items = dropdownRef.current.querySelectorAll("li");
        if (items.length < 1) {
          alert("nvjfb");
          return;
        }

        inputRef.current && inputRef.current.blur();

        if (event.key === "ArrowDown") {
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex < items.length - 1 ? prevIndex + 1 : 0
          );
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : items.length - 1
          );
        } else if (event.key === "Enter" && focusedIndex !== -1) {
          event.preventDefault();

          items[focusedIndex].click();
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedIndex, dropdownRef]);

  const callSearchAPI = async (query) => {
    setLoading(true);
    if (query) {
      query = query.trim();
      const emptyQuery = query.trim();
      if (emptyQuery === "") {
        setEmptyQuery(true);
        setShowMsg(true);
        return;
      } else {
        setEmptyQuery(false);
        setShowMsg(true);
      }

      if (query !== "" && query !== undefined) {
        await fetch(
          `${API_HOST}/${endpoints.searchbarQuery}${query}&per_page=5`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            setShowMsg(true);
            if (res.status === 200) {
              setProductList(res.data.products);
              if (res.data.products.length > 0) {
                setShowEmptyList(false);
              } else {
                setShowEmptyList(true);
              }
              setTotalResult(res.data.total);
            } else {
              if (res.message) {
                setShowEmptyList(true);
                setErrorMsg(res.message);
              }
            }
          });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location.pathname == "/search") {
      const debounceTimeout = setTimeout(() => {
        showSearchBackground(false);
        setRecentSearchShowDropdown(false);
        if (searchQuery !== "") {
          const base64EncodedQuery = btoa(searchQuery);
          navigate(`/search?query=${base64EncodedQuery}`);
        } else {
          if (inputRef.current) {
            inputRef.current.blur();
          }
          navigate("/products");
        }
      }, 500);
      return () => {
        clearTimeout(debounceTimeout);
      };
    } else {
      setLoading(true);
      const debounceTimeout = setTimeout(() => {
        setFocusedIndex(-1);
        handleSearch();
      }, 500);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  useEffect(() => {
    if (location.pathname == "/search") {
      const queryParams = new URLSearchParams(location.search);
      const searchQueryParams = atob(queryParams.get("query"));
      setSearchQuery(searchQueryParams);
    } else {
      setSearchQuery("");
    }
  }, [location.pathname]);

  const handleSearch = () => {
    if (searchQuery !== "") {
      callSearchAPI(searchQuery);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const handleInputChange = (event) => {
    setShowMsg(false);
    setProductList([]);
    setShowEmptyList(false);
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQuery", value);
  };

  const clearSearch = () => {
    sessionStorage.setItem("searchQuery", "");
    setSearchQuery("");
    setRecentSearchShowDropdown(false);
    showSearchBackground(false);
    setProductList([]);
    setShowDropdown(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
    if (location.pathname == "/search") {
      navigate("/products");
    }
  };

  const handleProductClick = (slug) => {
    setSearchQuery("");
    navigate(`${navigation.navigateToProductList}/${slug}`);
    setShowDropdown(false);
  };
  useEffect(() => {
    const data = getDataFromCookies("updatedSearches");
    if (data != null) {
      setRecentSearches(data);
    }
    window.addEventListener("popstate", clearSearch);
    return () => {
      window.removeEventListener("popstate", clearSearch);
    };
  }, []);

  // Function to remove HTML tags from a string
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    query = query.trim();

    // Remove HTML tags from the text
    const strippedText = stripHtmlTags(text);

    const regex = new RegExp(`(${query})`, "gi");
    const match = regex.exec(strippedText);

    if (!match) return text;

    const matchIndex = match.index;
    const matchLength = match[0].length;

    const maxLength = 50;

    const startIndex = Math.max(matchIndex - maxLength / 2, 0);
    const endIndex = Math.min(
      matchIndex + matchLength + maxLength / 2,
      strippedText.length
    );

    const prefix = strippedText.substring(startIndex, matchIndex);
    const highlighted = strippedText.substring(
      matchIndex,
      matchIndex + matchLength
    );
    const suffix = strippedText.substring(matchIndex + matchLength, endIndex);

    const highlightedText = (
      <>
        {prefix}
        <span className="highlight">{highlighted}</span>
        {suffix}
      </>
    );

    return endIndex < strippedText.length ? (
      <>{highlightedText}...</>
    ) : (
      highlightedText
    );
  };

  useEffect(() => {
    setSearchQuery("");
    setRecentSearchShowDropdown(false);
  }, [clearSeachBox]);

  return (
    <>
      {showFeatures.searchbar && (
        <div
          className="box-container"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {location.pathname != "/search" && searchQuery && (
            <div
              className="searchQueryBackGroundContainer"
              onClick={() => {
                setSearchQuery("");
              }}
            ></div>
          )}
          <div className="serachBarAllContainer">
            <div className="search-input-container">
              <div className="searchbarInputContainer">
                <input
                  ref={inputRef}
                  type="text"
                  value={searchQuery}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && searchQuery.trim() !== "") {
                      showSearchBackground(false);
                      setRecentSearchShowDropdown(false);
                      const base64EncodedQuery = btoa(searchQuery);
                      navigate(`/search?query=${base64EncodedQuery}`);
                      setRecentSearches((prevSearches) => {
                        // Keep only the latest five searches
                        const filteredarray = prevSearches.filter(
                          (e) => e !== searchQuery.trim()
                        );
                        const updatedSearches = [
                          searchQuery.trim(),
                          ...filteredarray,
                        ];

                        // Insert the element at index 0
                        return updatedSearches.slice(0, 5);
                      });
                      setDataToCookies("updatedSearches", recentSearches);
                    } else if (
                      event.key === "Enter" &&
                      searchQuery.trim() !== ""
                    ) {
                      navigate("/products");
                    }
                  }}
                  onChange={handleInputChange}
                  onClick={() => {
                    setRecentSearchShowDropdown(true);
                    showSearchBackground(true);
                    setDataToCookies("updatedSearches", recentSearches);
                  }}
                  className="search-input"
                  placeholder="Search for products..."
                />
              </div>
              <button
                className="search-action search-icon-container"
                onClick={clearSearch}
                aria-label={searchQuery ? "Clear search" : "Search"}
              >
                {searchQuery ? (
                  <span className="clear-button">
                    <FontAwesomeIcon icon={faTimes} className="clear-icon" />
                  </span>
                ) : (
                  <span aria-label="search-button" className="search-button">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                  </span>
                )}
              </button>
            </div>
          </div>

          {searchQuery == "" &&
            recentSearchShowDropdown &&
            recentSearches.length > 0 && (
              <div
                className="recent_search_div"
                ref={recentSearchDivRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="searchDropDown">
                  <ul className={"dropdown-list"}>
                    {recentSearches.map((search, index) => (
                      <li
                        className="search_recent_items_list dropdownItem"
                        key={index}
                        onClick={() => {
                          setRecentSearchShowDropdown(false);
                          showSearchBackground(false);
                          const base64EncodedQuery = btoa(search);
                          navigate(`/search?query=${base64EncodedQuery}`);
                          setRecentSearchShowDropdown(false);
                          showSearchBackground(false);
                          recentSearches.splice(index, 1);
                          recentSearches.unshift(search);
                        }}
                      >
                        <span>
                          <FontAwesomeIcon
                            style={{
                              fontSize: "14px",
                              marginRight: "12px",
                              color: "#989898",
                            }}
                            icon={faHistory}
                          />
                        </span>{" "}
                        {search}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          <>
            {searchQuery &&
              searchQuery.trim() !== "" &&
              location.pathname != "/search" && (
                <>
                  {productList.length > 0 ? (
                    <div className="searchDropDown">
                      {showDropdown && showMsg && (
                        <>
                          {!emptyQuery && (
                            <ul className={"dropdown-list"} ref={dropdownRef}>
                              {productList.length > 0 ? (
                                <>
                                  {productList.map((product, index) => (
                                    <li
                                      key={product.id}
                                      onClick={(e) => {
                                        setSearchQuery("");
                                        showSearchBackground(false);
                                        setRecentSearchShowDropdown(false);
                                        handleProductClick(product.slug);
                                        e.preventDefault();
                                      }}
                                      style={{
                                        boxShadow:
                                          index == focusedIndex
                                            ? " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                                            : "none",
                                      }}
                                    >
                                      <div className="dropdownItem">
                                        <div className="imgContainer">
                                          <LazyImage
                                            height={40}
                                            width={40}
                                            src={
                                              imgHost +
                                              "/" +
                                              product.thumb_image
                                            }
                                            alt="image"
                                          />
                                        </div>
                                        <div className="nameContainer">
                                          <p className="sr-prod-name">
                                            {highlightText(
                                              product.name,
                                              searchQuery
                                            )}
                                          </p>
                                          {product.description && (
                                            <p className="sr-prod-desc">
                                              {highlightText(
                                                product.description,
                                                searchQuery
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                  {totalResult > 5 && (
                                    <div className="showMoreSearchResults">
                                      <p
                                        onClick={() => {
                                          if (searchQuery !== "") {
                                            showSearchBackground(false);
                                            setRecentSearchShowDropdown(false);
                                            const base64EncodedQuery =
                                              btoa(queryString);
                                            navigate(
                                              `/search?query=${base64EncodedQuery}`
                                            );
                                          }
                                        }}
                                      >
                                        View All ({totalResult})
                                      </p>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className="dropdown-list-NoProducts">
                                  {showEmptyList && { errorMsg }}
                                </div>
                              )}
                            </ul>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="dropdown-list-NoProducts">
                      {loading && "Searching..."}
                      {showEmptyList && "No Products available!"}
                    </div>
                  )}
                </>
              )}
          </>
        </div>
      )}
    </>
  );
};

export default SearchBar;
